%btn {
  all: unset;
  background-color: transparent;
  color: #458ff6;
  border: 1.7px solid #458ff6;
  font: lighter 1.8rem 'Alata', sans-serif;
  letter-spacing: 0.2px;
  border-radius: 55px;
  padding: 1rem 2rem;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  display: inline-block;

  // Hover
  &:hover {
    background-color: #458ff6;
    color: white;
  }

  // Click
  &:active {
    transform: scale(0.9);
  }
}
