@import './helpers/_variables'; // Variables
@import './components/_btn'; // Btn
@import './layout/header'; // header

footer {
  width: 100%;
  background-image: $gradientColor;
  padding: 7rem 3rem 7rem 10rem;
  position: relative;
  display: grid;
  grid-gap: 4rem 0;
  grid-template-columns: repeat(4, 1fr);

  // lg 992px
  @media (max-width: 1150px) {
    grid-template-columns: repeat(3, 1fr);
  }

  // ms 350px
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding-left: 10rem;
  }

  // // sm 350px
  // @media (max-width: 600px) {
  //   grid-template-columns: 1fr;
  // }

  // Shape
  .shape {
    position: absolute;
    z-index: -2;
    top: -6rem;
    right: 8rem;
  }

  // Trafaglar
  .trafaglar {
    margin-right: 3.5rem;

    // logo
    .logo {
      display: flex;
      margin-bottom: 2.5rem;

      .t {
        background-color: #fff;
        width: 4.1rem;
        height: 4.1rem;
        border-radius: 50%;
        color: #458ff6;
        display: flex;
        justify-content: center;
        align-items: center;
        font: bold 2.6rem 'Alata', sans-serif;
        text-transform: capitalize;
        margin-right: 1rem;
      }

      .trafalgar {
        align-self: center;
        font: bold 2.4rem 'Alata', sans-serif;
        text-transform: capitalize;
        color: #fff;
      }
    }

    p {
      color: #ffffffea;
      font: lighter 1.6rem/2.8rem 'Alata', sans-serif;
      width: 391px;
      text-align: left;
      margin-bottom: 3rem;
    }

    // CopyRight
    .Copr {
      h3 {
        color: #ffffffea;
        font: lighter 1.6rem/2.8rem 'Alata', sans-serif;
        width: 391px;
        margin-bottom: 3rem;
      }

      h2 {
        color: #ffffffea;
        font: lighter 1.4rem 'Alata', sans-serif;
        width: 391px;

        a {
          all: unset;
          display: inline-block;
          cursor: pointer;
          font: lighter 1.2rem 'Alata', sans-serif;
          color: #ffffffd5;
          font-style: italic;
          transition: 0.2s;

          // Hover
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  // Company
  .company {
    h3 {
      color: #fff;
      font: bold 2rem 'Alata', sans-serif;
      margin-bottom: 1.5rem;
    }

    ul {
      all: unset;
      margin-left: -8px;
      display: flex;
      flex-direction: column;
      li {
        all: unset;
        margin: 1rem;

        a {
          all: unset;
          display: inline-block;
          cursor: pointer;
          font: lighter 1.5rem 'Alata', sans-serif;
          color: #ffffffd5;
          transition: 0.2s;

          // Hover
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  // Region
  .region {
    @extend .company;
  }

  // Help
  .help {
    @extend .company;
  }
}
